/***********************************************
         Invenio app RDM Image Overrides
***********************************************/

.ui.placeholder.image {
  &.mini {
    height: @miniWidth;
    width: @miniWidth;
  }

  &.tiny {
    height: @tinyWidth;
    width: @tinyWidth;
  }

  &.small {
    height: @smallWidth;
    width: @smallWidth;
  }

  &.medium {
    height: @mediumWidth;
    width: @mediumWidth;
  }

  &.large {
    height: @largeWidth;
    width: @largeWidth;
  }

  &.big {
    height: @bigWidth;
    width: @bigWidth;
  }

  &.huge {
    height: @hugeWidth;
    width: @hugeWidth;
  }

  &.massive {
    height: @massiveWidth;
    width: @massiveWidth;
  }
}

.ui.image.community-image img {
  max-height: @tinyWidth;
  object-fit: contain;
}

.ui.image.community-logo {

  img {
    height: @communityItemLogoWidth !important; // needs to override height set for images in items by SUI
    object-fit: contain;
  }

  &.settings {
    img {
      height: @mediumWidth !important;
    }
  }

}

.ui.items{
  .ui.image.community-logo {
    img {
      height: @communityItemLogoWidth !important; // needs to override height set for images in items by SUI
      width: @communityItemLogoWidth !important; // needs to override height set for images in items by SUI
      object-fit: contain;
    }
  }
}


.ui.image.community-header-logo {
  height: inherit;
  object-fit: contain;
}

.image.avatar.tiny {
  height: @lineHeight;
}
