/***********************************************
         Invenio Theme Header Overrides
***********************************************/


.ui.reset-password.header {
    margin: 40px 0;
}

.ui.login.header {
    margin: 40px 0;
}

.ui.login.segment {
    padding: 15px 40px 40px 40px;
}


.ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6)  {
    font-size: 1em;
}


/*--- Negative ---*/
.ui.negative.header {
  color: @negativeColor !important;
}
a.ui.negative.header:hover {
  color: @negativeHover !important;
}
.ui.negative.dividing.header {
  border-bottom: @dividedColoredBorderWidth solid @negativeColor;
}
