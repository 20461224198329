/***********************************************
         Invenio App RDM Message Overrides
***********************************************/


.ui.flashed.message,
.ui.form .flashed.message {
  &.manage {
    padding: 1em 0;
    margin-bottom: 1em;
  }
}

#record-tab-panel > .banners .ui.flashed.message.manage {
  padding: 1rem;
}

.ui.message.file-box-message {
  box-shadow: none !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  margin: 0 !important;
}

.ui.message .ui.checkbox label {
  color: currentColor;
}

.ui.message .ui.checkbox {
  margin-top: @defaultMargin / 2.5;
}
